import styled from 'styled-components';

type bg = 'purple' | 'white';

interface PropsSection {
  backgroundUrl?: string;
  bgContainer?: bg;
}

export const ModalForm = styled.div`
  top: 115px;
  left: 0px;
  width: 100%;
  height: 850px;
  position: absolute;
  background: #f2c6c6 0% 0% no-repeat padding-box;
  opacity: 0.95;
  display: flex;
  justify-content: center;
  div {
    max-width: var(--container);
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 170px;
    span:nth-child(1) {
      position: absolute;
      right: 10%;
      top: 8%;
      cursor: pointer;
    }
    span {
      color: white;
      font-size: 20px;
      font-family: Roboto-SemiBold;
      letter-spacing: 0.2px;
      margin-top: 30px;
    }
  }
`;

export const Container = styled.div`
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;

  &.flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }
`;

export const Form = styled.form`
  flex: 0 0 415px;
  background: var(--white);
  border-radius: 32px;
  height: 660px;
  padding: 37px;

  .head {
    font-size: 18px;
    line-height: 24px;
    font-family: Roboto-Bold;
    margin-bottom: 20px;
  }

  @media (max-width: 1279px) {
    min-height: 660px;
    height: auto;
    flex: 1;
  }
`;

export const Section = styled.section<PropsSection>`
  &.bg {
    &.banner {
      background-position: 45% 75%;
    }
    &.image {
      background-image: url('${({ backgroundUrl }) => backgroundUrl}');
      background-repeat: no-repeat;
      background-size: cover;
    }
    @media (max-width: 1279px) {
      &.banner {
        background-position: center;
      }
    }
    &.height-form {
      height: 850px;
    }
    &.data {
      width: 100%;
      height: 250px;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      color: #d34c52;
      font-family: Roboto;
      font-size: 17px;
      img {
        margin-top: 300px;
      }
      div:nth-child(2) {
        margin-top: 20px;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      select {
        width: 170px;
        height: 46px;
        color: #d48290;
        margin-left: 20px;
        outline: 0;
        font-size: 17px;
        background-color: #fee5e5;
        border: 1px solid #fee5e5;
      }
      select:focus {
        outline: none;
      }
      @media only screen and (max-width: 1279px) {
        img {
          margin-top: 15%;
          margin-bottom: 10px;
        }
        div:nth-child(2) {
          margin-top: 100px;
          flex-direction: column;
        }
        select {
          margin: 15px 0px 0px 0px;
        }
      }
    }
    &.group {
      display: flex;
      align-items: center;
      min-height: 100px;
      padding-top: 200px;
      background-color: #f2c6c6;
      justify-content: center;
      div {
        width: 100%;
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: 310px 310px 310px 310px;
        max-width: var(--container);
        .no-brands {
          width: var(--container);
          justify-content: center;
          align-items: center;
          height: 150px;
          text-align: center;
          color: #bf1f38;
          font-family: Roboto-Bold;
          display: flex;
          margin-bottom: 30px;
          font-weight: bold;
        }
        .image-button {
          width: 276px;
          height: 330px;
          display: flex;
          flex-direction: column;
          img:nth-child(2) {
            width: 165px;
            height: 163px;
            position: absolute;
            margin-left: 37px;
            margin-top: 20px;
            border-radius: 20px;
          }
          button {
            width: 164px;
            height: 46px;
            margin-bottom: 50px;
            background: #a22036;
            color: var(--white);
            border: 1px solid #a22036;
            border-radius: 6px;
            margin-left: 80px;
          }
        }
      }
      @media only screen and (max-width: 1279px) {
        div {
          grid-template-columns: 80% 80%;
          justify-content: center;
          padding: 5%;
          .no-brands {
            width: 100%;
            height: 70px;
            margin-top: 50px;
            margin-left: 50%;
          }
          .image-button {
            width: inherit;
            height: inherit;
            img:nth-child(2) {
              height: 165px;
              margin-top: -63px;
            }
          }
        }
      }
      @media only screen and (max-width: 480px) {
        div {
          grid-template-columns: 280px;
          justify-content: start;
          .no-brands {
            width: 100%;
            height: 70px;
            margin-top: 20px;
            margin-left: 0px;
          }
          .image-button {
            img {
              margin-right: 20%;
            }
            img:nth-child(2) {
              width: 140px;
              height: 150px;
              position: absolute;
              margin-left: 6px;
              margin-top: 18px;
              border-radius: 20px;
            }
            button {
              margin-left: 10px;
            }
          }
          img {
            width: 230px;
            height: 212px;
          }
        }
      }
    }
    &.bg-color {
      background-position: center;
    }
    &.campaign {
      width: 100%;
      height: 800px;
      background-color: #fee5e5;
      display: flex;
      align-items: center;
      place-content: center;
      .bg-color-campaign {
        background-color: var(--white);
      }
      .flex-between {
        display: flex;
        justify-content: space-between;
      }
      .container {
        width: 100%;
        max-width: var(--container);
        height: 665px;
        background-color: #fee5e5;
        flex-direction: row;
        .campaign-1 {
          width: 49%;
          height: 665px;
          flex-direction: column;
          .campaign-2 {
            width: 100%;
            height: 200px;
          }
          .campaign-3 {
            width: 100%;
            height: 436px;
            flex-direction: row;
            .campaign-4 {
              width: 48%;
              height: inherit;
              flex-direction: column;
              .campaign-5 {
                height: 47%;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1279px) {
      &.group {
        padding-top: 50px;
      }
      &.height-form {
        height: 700px;
      }
      &.campaign {
        height: 1828px;
        padding-top: 10px;
        .container {
          height: 1362px;
          flex-direction: column;
          .campaign-1 {
            width: 100%;
          }
        }
      }
    }
    @media only screen and (max-width: 480px) {
      &.height-form {
        height: 400px;
      }
      &.campaign {
        height: 902px;
        .container {
          height: 762px;
          .campaign-1 {
            width: 100%;
            height: 375px;
            .campaign-2 {
              height: 120px;
            }
            .campaign-3 {
              height: 240px;
              .campaign-4 {
                width: 48%;
                .campaign-5 {
                  height: 47%;
                }
              }
            }
          }
        }
      }
    }
    .banner-top {
      flex: 0 0 419px;

      @media (max-width: 1279px) {
        flex-basis: 326px;
        height: 785px;
      }

      @media (max-width: 607px) {
        flex-basis: 312px;
        height: 290px;
      }
    }
  }
  &.bg-form-lg {
    padding: 5%;
  }
  @media (max-width: 1279px) {
    &.bg-form-md {
      height: 800px;
      background-color: #f2c6c6;
      padding: 10%;
      display: flex;
      place-content: center;
    }
  }
  @media (max-width: 607px) {
    &.bg-form-lg {
      height: 400px;
      width: 100%;
    }
  }
`;
