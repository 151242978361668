import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

import Acknowledgment from 'components/Acknowledgment';
import FooterB2B from 'components/Footer/B2B';
import HeaderB2B from 'components/Header/B2B';
import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import {
  Li,
  BannerInicio,
  FormPlanner,
  Vantagens,
  PositionVantagens,
  Funciona,
  Conheca,
  Video,
  Display,
} from './styles';

const Marcas: React.FC = () => {
  const ContRef = useRef(null);
  const FuncRef = useRef(null);
  const ConheRef = useRef(null);
  const TopRef = useRef(null);
  const { success } = useParams();
  const showElement = success === 'agradecimento';

  const scrollToRef = (ref: any) =>
    window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });

  useHubspotForm({
    portalId: '6894615',
    formId: 'cde4d371-1a2e-477c-8933-e6b482ea86e9',
    target: '#my-hubspot-form',
  });

  return (
    <>
      <HeaderB2B bgContainer="white">
        <Li>
          <button type="button" onClick={() => scrollToRef(ContRef)}>
            Contato
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(FuncRef)}>
            Como funciona
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(ConheRef)}>
            Conheça o houpa!
          </button>
        </Li>
        <Li purple>
          <a
            href="https://negocios.houpa.app/download/empresas"
            target="_blank"
            rel="noreferrer">
            Baixe o APP
          </a>
        </Li>
      </HeaderB2B>
      {showElement && (
        <Acknowledgment
          title="Obrigado!"
          text="Nós enviamos o Planner houpa! para o seu e-mail. Aproveite!"
          maxWidth="600px"
        />
      )}
      <BannerInicio ref={TopRef}>
        {showElement && (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5511991299223"
            className="contact-us">
            <i className="icon icon-hand-click" />
            <span>
              <h1 id="title">Entre em contato!</h1>
              Clique aqui para falar com a <br /> nossa equipe comercial.
            </span>
          </a>
        )}
        <div className="orientationTxtBanner">
          <h1>Crie sua loja virtual GRATUITA hoje mesmo!</h1>
          <h3>
            Mais de 30.000 multimarcas varejistas de todo Brasil esperam por sua
            marca atacadista no houpa!
          </h3>
          <div className="orientationEstiloColumn">
            <h4 id="tituloh4">Todos os estilos se conectam aqui:</h4>
            <div className="orientationRow">
              <div className="orientationEstiloRow">
                <h4>
                  <i className="icon icon-check" />
                  Moda Praia
                </h4>
                <h4>
                  <i className="icon icon-check" />
                  Moda Fitness
                </h4>
                <h4>
                  <i className="icon icon-check" />
                  Moda Clássica
                </h4>
              </div>
              <div className="orientationEstiloRow2">
                <h4>
                  <i className="icon icon-check" />
                  Moda Casual
                </h4>
                <h4>
                  <i className="icon icon-check" />
                  Moda Evangélica
                </h4>
                <h4>
                  <i className="icon icon-check" />E muito mais!
                </h4>
              </div>
            </div>
            <FormPlanner ref={ContRef}>
              <div className="tituloPlanner">
                <h1>
                  Preencha seus dados e receba gratuitamente um planner 2021 de
                  ações de marketing para impulsionar o seu negócio.
                </h1>
              </div>
              <div id="my-hubspot-form" />
            </FormPlanner>
          </div>
        </div>
      </BannerInicio>
      <Display>
        <div className="display" />
      </Display>
      <PositionVantagens>
        <div className="left">
          <Vantagens>
            <div className="orientationVantagens">
              <div className="iconVantagens">
                <img
                  src="http://assets.houpa.app/images/houpa_hotsite/marcas/closet_Prancheta.svg"
                  alt=""
                />
              </div>
              <div className="txtVantagens">
                <h1>Economia</h1>
                <h2>Economize com viagens até os polos atacadistas.</h2>
              </div>
            </div>
          </Vantagens>

          <Vantagens>
            <div className="orientationVantagens">
              <div className="iconVantagens">
                <img
                  src="http://assets.houpa.app/images/houpa_hotsite/marcas/relatorios.svg"
                  alt=""
                />
              </div>
              <div className="txtVantagens">
                <h1>Autoatendimento</h1>
                <h2>Veja produtos, variantes e estoque tudo em tempo real.</h2>
              </div>
            </div>
          </Vantagens>

          <Vantagens>
            <div className="orientationVantagens">
              <div className="iconVantagens">
                <img
                  src="http://assets.houpa.app/images/houpa_hotsite/marcas/shopping_cart_Prancheta 1.svg"
                  alt=""
                />
              </div>
              <div className="txtVantagens">
                <h1>Facilidade</h1>
                <h2>Compre com várias marcas em um mesmo pedido.</h2>
              </div>
            </div>
          </Vantagens>
        </div>

        <div className="right">
          <Vantagens>
            <div className="orientationVantagens">
              <div className="iconVantagens">
                <img
                  src="http://assets.houpa.app/images/houpa_hotsite/marcas/roupas-01.svg"
                  alt=""
                />
              </div>
              <div className="txtVantagens">
                <h1>Variedade de Marcas</h1>
                <h2>Conecte-se com suas marcas atacadistas preferidas.</h2>
              </div>
            </div>
          </Vantagens>

          <Vantagens>
            <div className="orientationVantagens">
              <div className="iconVantagens">
                <img
                  src="http://assets.houpa.app/images/houpa_hotsite/marcas/comp.svg"
                  alt=""
                />
              </div>
              <div className="txtVantagens">
                <h1>Praticidade</h1>
                <h2>Realize seu pedido em qualquer hora e lugar.</h2>
              </div>
            </div>
          </Vantagens>

          <Vantagens>
            <div className="orientationVantagens">
              <div className="iconVantagens">
                <img
                  src="http://assets.houpa.app/images/houpa_hotsite/marcas/box-ok.svg"
                  alt=""
                />
              </div>
              <div className="txtVantagens">
                <h1>houpa!log</h1>
                <h2>
                  Unifique suas compras de diversas atacadistas em um único
                  frete
                </h2>
              </div>
            </div>
          </Vantagens>
        </div>
      </PositionVantagens>

      <Funciona ref={FuncRef}>
        <div className="orientationTxtFunciona">
          <h1>Como funciona?</h1>
          <h2>
            Para comprar e vender, atacadistas e varejistas solicitam conexão
            entre seus perfis. Ao se conectarem, a compra é liberada. Tudo isso
            em um aplicativo exclusivo para compra e venda no atacado.
          </h2>
        </div>
        <div className="imgFunciona">
          <img
            src="http://assets.houpa.app/images/houpa_hotsite/marcas/04b.png"
            alt=""
          />
          <h2>Aplicativo exclusivo para compra e venda no atacado</h2>
        </div>
      </Funciona>
      <Conheca ref={ConheRef}>
        <h1>Conheça o houpa!</h1>
        <div className="orientationUp">
          <div className="orientationtxtUp">
            <h1>O que é o houpa!?</h1>
            <h2>
              O houpa! é um marketplace de moda que veio para inovar o jeito de
              comprar e vender produtos. Somos a primeira rede de pedidos
              gratuita e exclusiva para o mercado atacadista brasileiro,
              ajudando no crescimento desde o pequeno produtor, passando pelo
              vendedor, até o consumidor final.
            </h2>
          </div>
          <div className="imgConheca">
            <img
              src="http://assets.houpa.app/images/houpa_hotsite/marcas/02-pc.png"
              alt=""
            />
          </div>
        </div>

        <div className="orientationUp">
          <div className="imgConheca">
            <img
              src="http://assets.houpa.app/images/houpa_hotsite/marcas/03.png"
              alt=""
            />
          </div>
          <div className="orientationtxtUp">
            <h1>Nossa Missão</h1>
            <h2>
              Nossa missão é levar a moda de todos para todos! Nosso ambiente
              foi elaborado para descomplicar as relações de negócio e facilitar
              o ciclo de compras do mercado da moda atacadista.
            </h2>
          </div>
        </div>
      </Conheca>
      <Video>
        <h1>Assista ao vídeo que preparamos para você!</h1>
        <div className="video">
          <ReactPlayer
            width="100%"
            height="100%"
            url=" https://www.youtube.com/watch?v=hL7yATltBtc"
            controls
          />
        </div>
        <div className="topo">
          <button type="button" onClick={() => scrollToRef(TopRef)}>
            <i className="icon icon-arrow-up" />
            Voltar ao topo
          </button>
        </div>
      </Video>

      <FooterB2B bgContainer="white" />
    </>
  );
};

export default Marcas;
