import React from 'react';
import TagManager from 'react-gtm-module';
import { Route, Routes } from 'react-router-dom';
import { useAnalytics } from 'hooks/useAnalytics';
import Multibrand from 'pages/Multibrand';
import FreightFree from 'pages/FreightFree';
import Convida from './pages/convida';
import Setup from './pages/Setup';
import Marcas from './pages/marcas';

const RoutesWrapper: React.FC = () => {
  const { startAnalytics } = useAnalytics();
  startAnalytics();
  return (
    <Routes>
      <Route path="/setup/:success" element={<Setup />} />
      <Route path="/setup" element={<Setup />} />
      <Route path="/convida" element={<Convida />} />
      <Route path="/multimarca" element={<Multibrand />} />
      <Route path="/multimarca/:success" element={<Multibrand />} />
      <Route path="/marca" element={<Marcas />} />
      <Route path="/marca/:success" element={<Marcas />} />
      <Route path="/frete_gratis" element={<FreightFree />} />
      <Route path="/frete_gratis/:success" element={<FreightFree />} />
    </Routes>
  );
};

export default RoutesWrapper;
