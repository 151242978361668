import React, { useCallback, useEffect, useRef, useState } from 'react';

import Acknowledgment from 'components/Acknowledgment';
import FooterB2B from 'components/Footer/B2B';
import HeaderB2B from 'components/Header/B2B';

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

import useMedia from 'hooks/useMedia';
import { useParams } from 'react-router-dom';

import { Container, Section, Form, ModalForm } from './styles';

import { api } from '../../services/api';

interface Brand {
  empresa_id_fk?: number;
  perfil_nome?: string;
  img_url_big?: string;
  minimum_value?: number;
  profile_url: string;
}

interface Value {
  id_config_freight_free: number;
  minimum_value: number;
}

const FreightFree: React.FC = () => {
  const { success } = useParams();
  const [brandsFreight, setBrandsFreight] = useState<Brand[]>([]);
  const [valueFreight, setValueFreight] = useState<Value[]>([]);
  const [showModal, setShowModal] = useState(true);
  const showElement = success === 'agradecimento';

  const tablet = useMedia('(max-width: 1279px)');
  const mobile = useMedia('(max-width: 607px)');

  useHubspotForm({
    portalId: '6894615',
    formId: '6a039a8d-aae3-4496-94f8-dcd191999b1a',
    target: '#my-hubspot-form',
  });

  const getBrands = async () => {
    const brands = await api.get('brands/freight-free');
    setBrandsFreight(brands.data.brands);
  };

  const getValuesFreight = async () => {
    const values = await api.get('brands/freight-free/values');
    setValueFreight(values.data.values);
  };

  const getValuesFreightById = async (id: string) => {
    const brands = await api.get(`brands/freight-free/`, {
      params: { id },
    });
    setBrandsFreight(brands.data.brands);
  };
  useEffect(() => {
    getBrands();
    getValuesFreight();
  }, []);

  const bannerImageLg = `banner-bg.png`;
  const bannerImageMd = 'banner-bg-md.png';

  const bannerBg = `https://assets.houpa.app/images/houpa_hotsite/freight-free/${
    tablet ? bannerImageMd : bannerImageLg
  }`;
  const bgColor = `https://assets.houpa.app/images/houpa_hotsite/freight-free/bg-color.png`;
  const data = `https://assets.houpa.app/images/houpa_hotsite/freight-free/lecturer.png`;
  const group = `https://assets.houpa.app/images/houpa_hotsite/freight-free/group.png`;
  const iconCheckGreen = `https://assets.houpa.app/images/houpa_hotsite/freight-free/icon-check-green.svg`;

  const sendToProfile = (link: string) => {
    const enviroment = process.env.REACT_APP_ENVIROMENT;
    const baseUrl =
      enviroment === 'dev'
        ? process.env.REACT_APP_BASE_URL_DEV
        : process.env.REACT_APP_BASE_URL_PRODUCTION;

    window.open(`${baseUrl}/${link}`, '_blank');
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <HeaderB2B bgContainer="white" colorFreightFree="red" />
      {!tablet && !mobile && showElement && showModal && (
        <ModalForm>
          <div>
            <span onClick={() => closeModal()} aria-hidden="true">
              X
            </span>
            <img src={iconCheckGreen} alt="" />
            <span>
              Agradecemos o envio. <br /> Entraremos em contato em breve!
            </span>
          </div>
        </ModalForm>
      )}
      <Section
        className="bg image banner bg-form-lg height-form"
        backgroundUrl={bannerBg}>
        <Container className="flex space-between">
          <div className="banner-top" />
          {!tablet && !mobile && (
            <Form className="no-md">
              <p className="head">
                Você é uma marca que vende no atacado? Preencha o formulário
                caso tenha interesse em participar das nossas campanhas.
                Entraremos em contato!.
              </p>
              <div id="my-hubspot-form" />
            </Form>
          )}
        </Container>
      </Section>
      {(tablet || mobile) && (
        <Section className="no-lg show-md bg-form-md">
          <Form>
            <Container>
              <p className="head">
                Você é uma marca que vende no atacado? Preencha o formulário
                caso tenha interesse em participar das nossas campanhas.
                Entraremos em contato!.
              </p>
              <div id="my-hubspot-form" />
            </Container>
          </Form>
        </Section>
      )}
      {/* <Section className="bg campaign">
        <div className="container flex-between">
          <div className="campaign-1 bg-color-campaign flex-between" />
          <div className="campaign-1 flex-between">
            <div className="campaign-2 bg-color-campaign" />
            <div className="campaign-3 flex-between">
              <div className="campaign-4 bg-color-campaign flex-between" />
              <div className="campaign-4 flex-between">
                <div className="campaign-5 bg-color-campaign" />
                <div className="campaign-5 bg-color-campaign" />
              </div>
            </div>
          </div>
        </div>
      </Section> */}
      <Section className="bg image data" backgroundUrl={bgColor}>
        <div>
          <img src={data} alt="" />
        </div>
        <div>
          <span>Para compras a partir de:</span>
          <select onChange={e => getValuesFreightById(e.target.value)}>
            {valueFreight?.map(value => (
              <option
                value={value.id_config_freight_free}
                key={value.id_config_freight_free}>
                {value.minimum_value.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </option>
            ))}
          </select>
        </div>
      </Section>
      <Section className="bg group">
        <div>
          {brandsFreight?.length > 0 ? (
            brandsFreight?.map(brand => (
              <div className="image-button" key={brand.empresa_id_fk}>
                <img src={group} alt="" />
                <img src={brand.img_url_big} alt="" />
                <button
                  type="button"
                  onClick={() => sendToProfile(brand.profile_url)}>
                  COMPRAR
                </button>
              </div>
            ))
          ) : (
            <div className="no-brands">
              <span>
                Não encontramos ofertas para compras a partir deste valor.
                <br /> Tente novamente selecionando outra opção. =)
              </span>
            </div>
          )}
        </div>
      </Section>
      <FooterB2B bgContainer="white" site={false} colorFreightFree="red" />
    </>
  );
};

export default FreightFree;
