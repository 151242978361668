import React, { useState, useRef, useCallback } from 'react';
import Chevron from './Chevron.js';

import './Accordion.css';

interface Props {
  title: string;
  content: string;
}

const Accordion: React.FC<Props> = ({ title, content }) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');

  const contentRef = useRef(null);

  const toggleAccordion = useCallback((_content: any) => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${_content.current.scrollHeight}px`,
    );
    setRotateState(
      setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate',
    );
  }, []);

  return (
    <div className="accordion__section">
      <button
        type="button"
        className={`accordion ${setActive}`}
        onClick={() => toggleAccordion(contentRef)}>
        <p className="accordion__title">{title}</p>
        <Chevron className={`${setRotate}`} width={10} fill="#777" />
      </button>
      <div
        ref={contentRef}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content">
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

export default Accordion;
