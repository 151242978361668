import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticsProvider } from './hooks/useAnalytics';
import RoutesWrapper from './Routes';
import GlobalStyles from './styles/GlobalStyles';

import './styles/icons.css';
import './styles/fonts-config.css';

const App: React.FC = () => {
  return (
    <AnalyticsProvider>
      <BrowserRouter>
        <GlobalStyles />
        <RoutesWrapper />
      </BrowserRouter>
    </AnalyticsProvider>
  );
};

export default App;
