import React, { createContext, useContext } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

interface EventProps {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}
interface AnalyticsContextData {
  startAnalytics: () => void;
  sendEventAnalytics: (props: EventProps) => void;
}

// início GTM
const GTM_KEY = process.env.REACT_APP_GTM_KEY;
if (GTM_KEY) {
  const tagManagerArgs = {
    gtmId: GTM_KEY,
  };

  TagManager.initialize(tagManagerArgs);
}
// Fim GTM

const ANALYTICS_KEY = process.env.REACT_APP_ANALYTICS_KEY;

const AnalyticsContext = createContext<AnalyticsContextData>(
  {} as AnalyticsContextData,
);

const AnalyticsProvider: React.FC = ({ children }) => {
  const startAnalytics = (): void => {
    if (!ANALYTICS_KEY) return;

    const URL = window.location.pathname + window.location.search;

    ReactGA.initialize(ANALYTICS_KEY);
    ReactGA.pageview(URL);
    ReactGA.ga('require', 'ecommerce');
  };

  function sendEventAnalytics(props: EventProps) {
    ReactGA.event(props);
  }

  return (
    <AnalyticsContext.Provider value={{ startAnalytics, sendEventAnalytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

function useAnalytics(): AnalyticsContextData {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useLoading deve ser usado com o AnalyticsProvider');
  }

  return context;
}

export { AnalyticsProvider, useAnalytics };
