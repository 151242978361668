import styled, { css, CSSProp } from 'styled-components';

type bg = 'purple' | 'white';

interface Props {
  bgContainer: bg;
}
interface IBgColor {
  purple: CSSProp;
  white: CSSProp;
}

const bgColor: IBgColor = {
  purple: css`
    background-color: var(--purple-business-dark);
  `,
  white: css`
    background-color: var(--white);
  `,
};

const color: IBgColor = {
  white: css`
    color: var(--purple-business-dark);
  `,
  purple: css`
    color: var(--white);
  `,
};

export const Back = styled.div<Props>`
  width: 100%;
  ${({ bgContainer }) => bgColor[bgContainer]};
`;

export const Container = styled.div<Props>`
  max-width: var(--container);
  width: 100%;
  ${({ bgContainer }) => color[bgContainer]};

  margin: 0 auto;
  padding: 35px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;

  &.start-md {
    @media (max-width: 1279px) {
      align-items: flex-start;
    }
  }

  @media (max-width: 1279px) {
    flex-direction: column-reverse;
  }
`;

export const Logo = styled.i<Props>`
  font-size: 42px;
  flex: 0.5;
  ${({ bgContainer }) => color[bgContainer]};
`;

export const Link = styled.a<Props>`
  ${({ bgContainer }) => color[bgContainer]};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  p {
    font-family: 'Roboto-Bold';
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 10px;
  }

  & ~ div {
    margin-left: 50px;
  }

  .address {
    font-size: 12px;
    line-height: 16px;
    color: var(--greyMedium3);
    margin-top: 10px;
  }

  .external-link {
    margin-top: 22px;
    font-family: Roboto-Bold;
    font-size: 10px;
    color: var(--greyMedium3);

    ul {
      display: flex;
      li {
        & ~ li {
          margin-left: 10px;
          &:before {
            content: '•';
            margin-right: 10px;
          }
        }
      }
    }
  }

  .stores-column {
    @media (max-width: 1279px) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 1279px) {
    flex: 1;
    & ~ div {
      margin-left: 0px;
      margin-bottom: 30px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex: 1;

  a {
    font-size: 32px;
    transition: opacity 0.2s;

    & ~ a {
      margin-left: 20px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &.stores {
    .icon {
      font-size: 32px;
      @media (max-width: 1279px) {
        font-size: 50px;
      }
    }

    a {
      & ~ a {
        @media (max-width: 1279px) {
          margin-left: 0px;
        }
      }
    }
  }

  &.link-text {
    a {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &.end {
    justify-content: flex-end;
  }

  &.column-md {
    @media (max-width: 1279px) {
      flex-direction: column;
    }
  }
`;
