import styled, { css } from 'styled-components';

interface PropsLi {
  purple?: boolean;
}

export const Geral = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

export const Li = styled.li<PropsLi>`
  a,
  button {
    font-family: Roboto-Medium;
    color: #ffffff;

    ${({ purple }) =>
      purple
        ? css`
            font-family: Roboto-Black;
            color: var(--purple-business-dark);
          `
        : css`
            color: var(--purple-business-dark);
          `}
  }
  @media only screen and (max-width: 1920px) {
    a,
    button {
      font-family: Roboto-Medium;
      color: #ffffff;
    }
  }
  @media only screen and (max-width: 1024px) {
    a,
    button {
      font-family: Roboto-Medium;
      color: #3f0b6d;
    }
  }
`;

export const Gradiente = styled.div`
  width: 100vw;
  height: 3000px;
  background-repeat: no-repeat;
  background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/bg@2x.png');
  background-size: cover;

  .bannerConvida {
    background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/bannerConvida3.png');
    width: 100vw;
    height: 700px;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: 1024px) {
      margin-top: 10px;
      background-size: cover;
      height: 615px;
    }
    @media only screen and (max-width: 375px) {
      margin-top: 10px;
      background-size: cover;
      height: 353px;
    }
  }
`;

export const Titulo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 644px;
  h1 {
    width: 100%;
    display: flex;
    margin-top: 20px;
    font-size: 48px;
    text-align: center;
    font-weight: 780;
    color: #3f0b6d;
    font-family: 'Poppins-SemiBold', sans-serif;
    margin-bottom: 10px;
  }
  h3 {
    width: 100%;
    margin-top: -10px;
    font-size: 22px;
    text-align: center;
    font-weight: 100;
    color: #3f0b6d;
    font-family: 'Roboto-Light', sans-serif;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    h1 {
      font-size: 36px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 375px) {
    width: 100%;
    h1 {
      height: 67px;
      font-size: 24px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 16px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

export const Inscricao = styled.div`
  display: flex;
  justify-content: center;

  .inscricao {
    background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/inscricao.png');
    width: 30%;
    height: 653px;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
  h1 {
    width: 100%;
    font-size: 28px;
    text-align: left;
    color: #3f0b6d;
    font-family: 'Poppins-SemiBold', sans-serif;
    margin-bottom: 20px;
  }
  h3 {
    width: 100%;
    margin-top: -10px;
    font-size: 24px;
    text-align: left;
    font-weight: 100;
    color: #3f0b6d;
    font-family: 'Poppins-Light', sans-serif;
  }
  .orientacao {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  @media only screen and (max-width: 1024px) {
    h1 {
      margin-left: 40px;
    }
    h3 {
      margin-left: 40px;
    }
  }

  @media only screen and (max-width: 375px) {
    h1 {
      width: 100%;
      text-align: center;
      font-size: 24px;
      margin-left: 0px;
    }
    h3 {
      text-align: center;
      margin-left: 0px;
    }
  }
`;

export const Form = styled.div`
  width: 100%;
  height: max-content;
  margin-top: 50px;
  #my-hubspot-form {
    width: 605px;
    height: 48px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  @media only screen and (max-width: 375px) {
    #my-hubspot-form {
      width: 340px;
      height: 48px;
    }
  }
`;

export const Participar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .video {
    width: 768px;

    @media only screen and (max-device-width: 1024px) {
      width: 768px;
      height: 432px;
    }
    @media only screen and (max-device-width: 380px) {
      width: 343px;
    }
  }
  h1 {
    width: 100%;
    margin-top: 80px;
    font-size: 48px;
    text-align: center;
    color: #3f0b6d;
    font-family: 'Poppins-SemiBold', sans-serif;
    @media only screen and (max-device-width: 1920px) {
      margin-top: 50px;
    }
    @media only screen and (max-device-width: 1024px) {
      margin-top: 400px;
    }
    @media only screen and (max-device-width: 375px) {
      font-size: 24px;
      width: 202px;
      margin-top: 430px;
    }
  }
  .moedas {
    width: 1059px;
    height: 120px;
    margin-top: 40px;
  }
  .ganhe {
    display: flex;
    @media screen and (max-width: 375px) {
      display: flex;
      flex-direction: column;
      h1 {
        width: 100%;
      }
      h2 {
        text-align: center;
        width: 100%;
      }
    }
    h1 {
      margin-top: 10px;
      margin-bottom: 40px;
      font-size: 58px;
      text-align: center;
      color: #3f0b6d;
      font-family: 'Poppins-SemiBold', sans-serif;
    }
    h2 {
      margin-top: 10px;
      font-size: 58px;
      font-weight: 100;
      font-family: 'Poppins-Light', sans-serif;
      color: #3f0b6d;
      margin-right: 10px;
    }
  }
  h3 {
    margin-top: -30px;
    font-size: 28px;
    text-align: center;
    color: #3f0b6d;
    font-weight: 100;
    font-family: 'Poppins-Light', sans-serif;
    margin-bottom: 40px;
  }

  h4 {
    margin-top: -10px;
    font-size: 14px;
    color: #3f0b6d;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 85px;
  }
  .celulares {
    background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/celulares.png');
    background-size: 90%;
    width: 1920px;
    height: 509px;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 1024px) {
      width: 577px;
      height: 1000px;
      background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/celulares_mobile.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
    @media screen and (max-width: 375px) {
      width: 343px;
      height: 594px;
      background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/celulares_mobile.png');
      margin-top: 10px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .moedas {
    background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/moedas.png');
    width: 100vw;
    height: 120px;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width: 1024px) {
      width: 577px;
      height: 242px;
      background-size: 100%;
      background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/moedas_mobile.png');
      background-repeat: no-repeat;
    }

    @media screen and (max-width: 375px) {
      width: 385px;
      height: 162px;
      background-size: 100%;
      background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/moedas_mobile.png');
      background-repeat: no-repeat;
    }
  }

  .assista {
    margin-top: 20px;
    font-size: 21px;
    color: #3f0b6d;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    @media screen and (max-width: 375px) {
      margin-top: 0px;
    }
  }
  .conheca {
    margin-top: -50px;
    width: 100%;
    @media screen and (max-width: 375px) {
    }
  }
  .premio {
    display: flex;
  }
  .Tplanos {
    font-size: 28px;
    color: #3f0b6d;
    text-align: center;
    font-weight: 600;
    font-family: 'Poppins-SemiBold', sans-serif;
    @media screen and (max-width: 1024px) {
      width: 392px;
    }
    @media screen and (max-width: 375px) {
      width: 336px;
      font-size: 24px;
    }
  }
  @media screen and (max-width: 375px) {
    .ganheh4 {
      margin-bottom: 40px;
    }
    h3 {
      font-size: 21px;
      width: 343px;
    }
  }
`;
export const Premio = styled.div`
  background-image: url('http://assets.houpa.app/images/houpa_hotsite/convida/premio.png');
  width: 100%;
  height: 286px;
  background-position: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
  }
  @media screen and (max-width: 375px) {
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
    height: 152px;
  }
`;

export const Plano = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;

  .espaco {
    margin-left: 10px;
  }
  .planos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 248px;
    background-color: #efefef;
    border-radius: 24px;
    padding: 20px;
    margin-bottom: 20px;

    .numerox {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: -20px;
      border-left: 3px solid #ffffff;
      height: 210px;
      padding-left: 15px;
      padding-top: 15px;

      .nx {
        font-size: 18px;
        text-align: left;
        color: #3f0b6d;
        font-weight: 900;
        font-family: 'Poppins', sans-serif;
        margin-top: 0px;
      }
      .textonx {
        margin-left: 5px;
        font-size: 14px;
        text-align: left;
        color: #3f0b6d;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
      }
    }
  }

  .orientationPlano {
    display: flex;
    flex-direction: column;
    align-items: left;

    h1 {
      margin: 0px;
      font-size: 28px;
      color: #3f0b6d;
      text-align: left;
      font-weight: 600;
      font-family: 'Poppins-SemiBold', sans-serif;
      margin-bottom: 10px;
    }
    .border {
      border-top: 3px solid #ffffff;
      width: 320px;
      h2 {
        margin-top: 10px;
        font-size: 14px;
        color: #3f0b6d;
        text-align: left;
        font-weight: 900;
        font-family: 'Roboto', sans-serif;
      }
      h3 {
        padding-bottom: 15px;
        margin-top: 5px;
        font-size: 13px;
        text-align: left;
        color: #3f0b6d;
        width: 299px;
        height: 90px;
      }
    }
  }

  @media screen and (max-width: 375px) {
    margin-bottom: -50px;
    .planos {
      display: flex;
      flex-direction: column;
      height: max-content;

      .numerox {
        width: 100%;
        border-top: 3px solid #ffffff;
        border-left: none;
      }
      .orientationPlano {
        h1 {
          width: 100%;
          text-align: center;
        }
        .border {
          h2 {
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
          }
          h3 {
            width: 100%;
            margin: auto;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

export const Pontuacao = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 899px;
  height: max-content;
  background-color: #efefef;
  border-radius: 53px;
  margin: 50px;
  h1 {
    margin-top: 35px;
    font-size: 28px;
    color: #3f0b6d;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 35px;
  }
  .ranking {
    padding: 0px 20px 0px 20px;
    background-color: #ffffff;
    border-radius: 28px;
    width: 723px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    h1 {
      font-size: 25px;
      text-align: left;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
    }
  }
  h2 {
    font-size: 24px;
    text-align: center;
    color: #3f0b6d;
    font-family: 'Poppins-Medium', sans-serif;
  }
  h3 {
    margin-top: -5px;
    font-size: 17px;
    color: #3f0b6d;
    text-align: center;
    font-weight: 600;
    font-family: 'Roboto-Medium', sans-serif;
  }

  @media only screen and (max-width: 1024px) {
    width: 608px;
    height: max-content;
    .ranking {
      width: 576px;
    }
    h2 {
      width: 388px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 375px) {
    width: 343px;
    height: max-content '';
    margin-top: 60px;
    border-radius: 24px;
    margin-bottom: 20px;
    h1 {
      width: 240px;
      font-size: 28px;
    }
    .ranking {
      width: 321px;
      height: 82px;
    }
    h2 {
      width: 298px;
      margin-bottom: 20px;
    }
    h3 {
      text-align: left;
      width: 258px;
    }
    .aviso {
      width: 100%;
      text-align: center;
    }
  }
`;
export const Duvidas = styled.div`
  display: flex;
  justify-content: center;
  background-color: #efefef;
  width: 1920px;
  height: max-content;
  padding-top: 130px;
  h1 {
    font-size: 28px;
    text-align: left;
    color: #000000;
    font-weight: 600;
    font-family: 'Poppins-SemiBold', sans-serif;
    margin-bottom: 20px;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .frequente {
      margin-top: -150px;
      margin-bottom: 65px;
    }

    .perguntas {
      flex-wrap: wrap;
      // margin-top: 130px;
      //margin-left: 85px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    .perguntas {
      margin-left: 200px;
      width: 563px;
    }
    .flex .frequente {
      margin-top: -500px;
    }

    .accordion__section {
      margin-left: -150px;
    }
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin-left: 0px;
    img {
      width: 342px;
    }
    .perguntas {
      //margin-left: 200px;
      width: 355px;
    }
    .accordion {
      width: 100vw;
    }
    h1 {
      margin-top: 20px;
      font-size: 28px;
      width: 100%;
      text-align: center;
    }
    .flex .frequente {
      margin-top: -100px;
    }
  }
`;
export const Regras = styled.div`
  display: flex;
  width: 62%;
  p {
    font-size: 11px;
    text-align: left;
    color: #000000;
    height: 128px;
    margin-top: 40px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }
  @media screen and (max-width: 1024px) {
    width: 608px;
    height: 176px;
  }
  @media screen and (max-width: 375px) {
    width: 343px;
    height: 323px;
  }
`;
