import styled, { css, CSSProp } from 'styled-components';

type bg = 'purple' | 'white';

interface Props {
  bgContainer: bg;
}
interface IBgColor {
  purple: CSSProp;
  white: CSSProp;
}

const bgColor: IBgColor = {
  purple: css`
    background-color: var(--purple-business-dark);
  `,
  white: css`
    background-color: var(--white);
  `,
};

const color: IBgColor = {
  white: css`
    color: var(--purple-business-dark);
  `,
  purple: css`
    color: var(--white);
  `,
};

const bgColorInvert: IBgColor = {
  white: css`
    background-color: var(--purple-business-dark);
  `,
  purple: css`
    background-color: var(--white);
  `,
};

export const Back = styled.div<Props>`
  width: 100%;
  ${({ bgContainer }) => bgColor[bgContainer]};
`;

export const Container = styled.div`
  max-width: var(--container);
  width: 100%;
  color: var(--white);
  margin: 0 auto;
  padding: 35px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;

  position: relative;

  @media (max-width: 1279px) {
    padding: 15px 0px;
  }
`;

export const Logo = styled.i<Props>`
  font-size: 42px;
  flex: 0.5;
  ${({ bgContainer }) => color[bgContainer]};
`;

export const Link = styled.a``;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  ul {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;

    li {
      a,
      button {
        font-size: 14px;
        line-height: 17px;
      }

      & ~ li {
        margin-left: 50px;
      }
    }
  }

  @media (max-width: 1279px) {
    flex: 0;

    ul {
      background: var(--white);
      position: fixed;
      display: none;

      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding-left: 0px;

      flex-direction: column;
      transition: 0.7s;

      li {
        padding-left: 20px;
        width: 100%;
        border-bottom: 1px solid var(--greyLight1);

        a,
        button {
          font-size: 25px;
          line-height: 35px;
        }
        & ~ li {
          margin-top: 30px;
          margin-left: 0px;
        }
      }
    }
  }
`;

export const Social = styled.div<Props>`
  flex: 0.5 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    ${({ bgContainer }) => color[bgContainer]};
    font-size: 24px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const Menu = styled.div<Props>`
  flex: 5 1 730px;

  @media (max-width: 1279px) {
    flex: 0;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;

    .menu {
      width: 60px;
      height: 60px;

      @media (min-width: 1280px) {
        display: none;
      }
    }

    .hamburguer {
      position: relative;
      display: block;
      ${({ bgContainer }) => bgColorInvert[bgContainer]};
      width: 30px;
      height: 2px;
      top: 29px;
      left: 15px;
      transition: 0.5s ease-in-out;
    }

    .hamburguer:before,
    .hamburguer:after {
      ${({ bgContainer }) => bgColorInvert[bgContainer]};
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: 0.5s ease-in-out;
    }

    .hamburguer:before {
      top: -10px;
    }

    .hamburguer:after {
      bottom: -10px;
    }
  }
  input {
    display: none;

    &:checked {
      ~ label {
        position: fixed;
        right: 0;
        .menu {
          /* top: 35px;
          right: 20px; */

          .hamburguer {
            transform: rotate(45deg);
          }
          .hamburguer:after {
            background: transparent;
          }
          .hamburguer:before {
            transform: rotate(90deg);
            top: 0;
          }
        }
      }

      ~ ul {
        display: flex;
      }
    }
  }
`;

export const Label = styled.label``;
