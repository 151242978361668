import React, { useRef } from 'react';

import Acknowledgment from 'components/Acknowledgment';
import FooterB2B from 'components/Footer/B2B';
import HeaderB2B from 'components/Header/B2B';

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

import useMedia from 'hooks/useMedia';
import { useParams } from 'react-router-dom';

import {
  Container,
  Li,
  Section,
  Form,
  Button,
  Video,
  Flex,
  Grid,
  Card,
} from './styles';

const Multibrand: React.FC = () => {
  const ContactRef = useRef(null);
  const HowItWorksRef = useRef(null);
  const AboutHoupaRef = useRef(null);
  const BenefitsRef = useRef(null);
  const { success } = useParams();
  const showElement = success === 'agradecimento';

  const tablet = useMedia('(max-width: 1279px)');
  const mobile = useMedia('(max-width: 607px)');

  useHubspotForm({
    portalId: '6894615',
    formId: '24cd2607-16b5-4f2f-9c75-91caf0e42c9d',
    target: '#my-hubspot-form',
  });

  const scrollToRef = (ref: any) =>
    window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });

  const goToStore = () => {
    window.open('https://negocios.houpa.app/download/empresas', '_blank');
  };

  const bannerImageLg = `banner-multibrand.png`;
  const bannerImageMd = 'banner-multibrand-md.png';

  const bannerUrl = `https://assets.houpa.app/images/houpa_hotsite/multibrand/${
    tablet ? bannerImageMd : bannerImageLg
  }`;

  const bannerBg = `https://assets.houpa.app/images/houpa_hotsite/multibrand/banner-multibrand-bg.png`;
  return (
    <>
      <HeaderB2B bgContainer="white">
        <Li>
          <button type="button" onClick={() => scrollToRef(ContactRef)}>
            Contato
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(HowItWorksRef)}>
            Como funciona
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(AboutHoupaRef)}>
            Conheça o houpa!
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(BenefitsRef)}>
            Vantagens
          </button>
        </Li>
        <Li purple>
          <a
            href="https://negocios.houpa.app/download/empresas"
            target="_blank"
            rel="noreferrer">
            Baixe o APP
          </a>
        </Li>
      </HeaderB2B>

      {showElement && (
        <Acknowledgment
          title="Obrigado!"
          text="Nós enviamos o Planner houpa! para o seu e-mail. Aproveite!"
          maxWidth="600px"
        />
      )}

      <Section
        ref={ContactRef}
        className="bg image no-image-sm grey-color-sm "
        backgroundUrl={bannerUrl}>
        {showElement && (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5511991299223"
            className="contact-us">
            <i className="icon icon-hand-click" />
            <span>
              <h1 id="title">Entre em contato!</h1>
              Clique aqui para falar com a <br /> nossa equipe comercial.
            </span>
          </a>
        )}
        <Container className="flex space-between pt-77 pb-77">
          <div className="banner-top">
            <h1 className="purple">
              Compre de marcas atacadistas de todo Brasil sem sair de casa
            </h1>
            <h2>
              Mais de 1.000 marcas atacadistas com diferentes estilos de roupas
              esperam por multimarcas varejistas no houpa!
            </h2>

            <div className="list">
              <p>Todos os estilos se conectam aqui:</p>
              <ul>
                <li>
                  <i className="icon icon-check" />
                  Moda Praia
                </li>
                <li>
                  <i className="icon icon-check" />
                  Moda Fitness
                </li>
                <li>
                  <i className="icon icon-check" />
                  Moda Clássica
                </li>
                <li>
                  <i className="icon icon-check" />
                  Moda Casual
                </li>
                <li>
                  <i className="icon icon-check" />
                  Moda Evangélica
                </li>
                <li>
                  <i className="icon icon-check" />E muito mais!
                </li>
              </ul>
            </div>
          </div>

          {!tablet && !mobile && !showElement && (
            <Form className="no-md">
              <p className="head">
                Preencha seus dados e receba gratuitamente um planner 2021 de
                ações de marketing para impulsionar o seu negócio.
              </p>

              <div id="my-hubspot-form" />
            </Form>
          )}
        </Container>
      </Section>
      {!mobile && (
        <Section className="bg image no-lg no-md show-sm grey-color-sm">
          <Container className="flex center">
            <div className="image-banner-sm">
              <img
                src="https://assets.houpa.app/images/houpa_hotsite/multibrand/banner-multibrand-sm.png"
                alt=""
              />
            </div>
          </Container>
        </Section>
      )}
      {(tablet || mobile) && (
        <Section className="no-lg show-md">
          <Form>
            <Container>
              <p className="head">
                Preencha seus dados e receba gratuitamente um planner 2021 de
                ações de marketing para impulsionar o seu negócio.
              </p>
              <div id="my-hubspot-form" />
            </Container>
          </Form>
        </Section>
      )}
      <Section ref={HowItWorksRef} className="bg color" bgContainer="purple">
        <Container className="flex center space-between pt-37 pb-66">
          <div className="text">
            <h1 className="white">Como funciona</h1>
            <p className="subtitle text-white">
              Para comprar e vender, atacadistas e varejistas solicitam conexão
              entre seus perfis. Ao se conectarem, a compra é liberada. Tudo
              isso em um aplicativo exclusivo para compra e venda no atacado.
            </p>
          </div>

          <figure>
            <img
              src="https://assets.houpa.app/images/houpa_hotsite/multibrand/app-info.png"
              alt=""
            />
            <figcaption className="text-white no-md">
              Aplicativo exclusivo para compra e venda no atacado
            </figcaption>
          </figure>
        </Container>
      </Section>
      <Section
        ref={AboutHoupaRef}
        className="aboutHoupa bg image resize-bg no-image-sm"
        backgroundUrl={bannerBg}>
        <Container className="flex center column pt-77 pb-66 aboutHoupa">
          <h1>Conheça o houpa!</h1>
          <Flex className="flex center column ">
            <Section>
              <Flex className="center space-between">
                <div className="text">
                  <h2 className="black">O que é o houpa!?</h2>
                  <p>
                    O houpa! é um marketplace de moda que veio para inovar o
                    jeito de comprar e vender produtos. Somos a primeira rede de
                    pedidos gratuita e exclusiva para o mercado atacadista
                    brasileiro, ajudando no crescimento desde o pequeno
                    produtor, passando pelo vendedor, até o consumidor final.
                  </p>
                </div>
                <figure>
                  <img
                    src="http://assets.houpa.app/images/houpa_hotsite/multibrand/notebook.png"
                    alt=""
                  />
                </figure>
              </Flex>
            </Section>
            <Section>
              <Flex className="space-between reverse-column-md">
                <figure>
                  <img
                    src="http://assets.houpa.app/images/houpa_hotsite/multibrand/mulher-missao.png"
                    alt=""
                  />
                </figure>
                <div className="text mission">
                  <h2 className="black">Nossa Missão</h2>
                  <p>
                    <strong>
                      Nossa missão é levar a moda de todos para todos!
                    </strong>
                    <br />
                    Nosso ambiente foi elaborado para descomplicar as relações
                    de negócio e facilitar o ciclo de compras do mercado da moda
                    atacadista.
                  </p>
                </div>
              </Flex>
            </Section>
          </Flex>
        </Container>
      </Section>
      <Section
        className="bg image aboutHoupa"
        backgroundUrl="https://assets.houpa.app/images/houpa_hotsite/multibrand/bg-video.png">
        <Container className="flex column center pt-77 pb-77 aboutHoupa">
          <h1 className="title-small-od">
            Assista ao vídeo que preparamos para você!
          </h1>
          <Video>
            <iframe
              src="https://www.youtube.com/embed/hL7yATltBtc"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video houpa!"
            />
          </Video>
        </Container>
      </Section>
      <Section ref={BenefitsRef} className="bg color" bgContainer="purple">
        <Container className="flex center column pt-77 pb-66 aboutHoupa">
          <h1 className="white title-small-od">
            Vantagens de comprar no houpa!
          </h1>
          <Grid className="mt-44">
            <Card>
              <div className="icon">
                <img
                  src="https://assets.houpa.app/images/houpa_hotsite/imgs-default/etiqueta.svg"
                  alt=""
                />
              </div>

              <h2 className="title">Economia</h2>
              <p className="description">
                Economize com viagens até os polos atacadistas.
              </p>
            </Card>
            <Card>
              <div className="icon">
                <img
                  src="https://assets.houpa.app/images/houpa_hotsite/imgs-default/roupas.svg"
                  alt=""
                />
              </div>
              <h2 className="title">Variedade de Marcas</h2>
              <p className="description">
                Conecte-se com suas marcas atacadistas preferidas.
              </p>
            </Card>
            <Card>
              <div className="icon">
                <img
                  src="https://assets.houpa.app/images/houpa_hotsite/imgs-default/relatorios.svg"
                  alt=""
                />
              </div>

              <h2 className="title">Autoatendimento</h2>
              <p className="description">
                Veja produtos, variantes e estoque tudo em tempo real.
              </p>
            </Card>
            <Card>
              <div className="icon">
                <img
                  src="https://assets.houpa.app/images/houpa_hotsite/imgs-default/dispositivos-coracao.svg"
                  alt=""
                />
              </div>
              <h2 className="title">Praticidade</h2>
              <p className="description">
                Realize seu pedido em qualquer hora a qualquer lugar.
              </p>
            </Card>
            <Card>
              <div className="icon">
                <img
                  src="https://assets.houpa.app/images/houpa_hotsite/imgs-default/cart-color.svg"
                  alt=""
                />
              </div>
              <h2 className="title">Facilidade</h2>
              <p className="description">
                Compre com várias marcas em um mesmo pedido.
              </p>
            </Card>
            <Card>
              <div className="icon">
                <img
                  src="https://assets.houpa.app/images/houpa_hotsite/imgs-default/box-ok.svg"
                  alt=""
                />
              </div>
              <h2 className="title">houpa!log</h2>
              <p className="description">
                Unifique suas compras de diversas atacadistas em um único
                pagamento.
              </p>
            </Card>
          </Grid>
        </Container>
      </Section>
      <Section
        className="bg color no-lg show-md return-top-top"
        bgContainer="white">
        <Container className="flex center ">
          <button type="button" onClick={() => scrollToRef(ContactRef)}>
            <i className="icon icon-arrow-up" />
            Voltar ao topo
          </button>
        </Container>
      </Section>
      <FooterB2B bgContainer="white" site={false} />
    </>
  );
};

export default Multibrand;
