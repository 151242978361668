import React, { useRef } from 'react';
import Acknowledgment from 'components/Acknowledgment';
import HeaderB2B from 'components/Header/B2B';
import FooterB2B from 'components/Footer/B2B';
import useMedia from 'hooks/useMedia';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { useParams } from 'react-router-dom';
import {
  Container,
  Main,
  Section,
  Col,
  Row,
  Card,
  Form,
  Li,
  FormContato,
  FormContatoTM,
} from './styles';

const Setup: React.FC = () => {
  const StoreRef = useRef(null);
  const ConsultancyRef = useRef(null);
  const ServicesRef = useRef(null);
  const PriceRef = useRef(null);
  const ContactRef = useRef(null);
  const { success } = useParams();
  const showElement = success === 'agradecimento';

  const FormHub = useHubspotForm({
    portalId: '6894615',
    formId: '638206c9-45cb-43df-a238-fd5ca2ad0e47',
    target: '#my-hubspot-form',
  });

  const scrollToRef = (ref: any) =>
    window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
  const desk = useMedia('(max-width: 1900px)');
  const tablet = useMedia('(max-width: 1279px)');
  const mobile = useMedia('(max-width: 607px)');

  const tamanho = tablet || mobile ? 338 : 538;
  let alinhamento;

  if (mobile === true) {
    alinhamento = '-65%';
  } else if (tablet === true) {
    alinhamento = '-5%';
  } else {
    alinhamento = '8%';
  }
  return (
    <>
      <HeaderB2B>
        <Li>
          <button type="button" onClick={() => scrollToRef(StoreRef)}>
            Loja online
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(ConsultancyRef)}>
            Consultoria SETUP
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(ServicesRef)}>
            Serviços
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(PriceRef)}>
            Preços
          </button>
        </Li>
        <Li>
          <button type="button" onClick={() => scrollToRef(ContactRef)}>
            Entre em contato
          </button>
        </Li>
      </HeaderB2B>

      <Main>
        {showElement && (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5511991299223"
            className="contact-us">
            <i className="icon icon-hand-click" />
            <span>
              <h1 id="title">Entre em contato!</h1>
              Clique aqui para falar com a <br /> nossa equipe comercial.
            </span>
          </a>
        )}
        <Container>
          <div className="description">
            <h1>Prepare-se para a nova realidade e aumente suas vendas.</h1>
            <p>Bem-vindo ao houpa! Negócios</p>

            <p>
              Sua marca acaba de dar o primeiro de muitos passos para uma nova
              realidade de negócio. Você está preparado para a transformação
              digital?
            </p>
            <p>
              Contrate o serviço de consultoria e SETUP houpa! para garantir
              sucesso com seus produtos e vendas.
            </p>
          </div>
        </Container>
        {!tablet && !mobile && (
          <FormContato>
            <div className="tituloPlanner">
              <h1>
                Estamos prontos para alavancar suas vendas! Vamos mudar o mundo
                da moda juntos?
              </h1>
            </div>
            <div id="my-hubspot-form" />
          </FormContato>
        )}
      </Main>
      {(tablet || mobile) && (
        <FormContatoTM>
          <div className="tituloPlanner">
            <h1>Estamos prontos para alavancar suas vendas!</h1>
            <h1 id="sub"> Vamos mudar o mundo da moda juntos?</h1>
          </div>
          <div id="my-hubspot-form" />
        </FormContatoTM>
      )}
      <Section ref={StoreRef}>
        <Container>
          <div className="image">
            <img
              src="http://assets.houpa.app/images/houpa_hotsite/setup/img-loja-online.png"
              alt=""
            />
          </div>
          <div className="description">
            <h1 className="lonline">
              Sua loja online estará pronta para divulgação e recebimento de
              muitos pedidos.
            </h1>

            <p className="lonlinep">
              Em conjunto com você entregaremos sua loja virtual pronta, linda,
              a cara da sua marca e sua equipe preparada e treinada para dar
              continuidade nas vendas online.
            </p>
          </div>
        </Container>
      </Section>

      <Section ref={ConsultancyRef} className="bg purple">
        <Container>
          <Col className="services-group p-top-44">
            <Col className="center">
              <h1 id="servico">O que o serviço de consultoria engloba?</h1>
              <p id="servicop">Configuração da loja virtual:</p>
            </Col>
            <Col className="services retracted m-top-40">
              <Row className="R1">
                <div className="description number">
                  <div className="count">1</div>
                  <h2 id="h2">Perfil</h2>
                  <p id="p1">
                    Criação do perfil de acordo com as regras comerciais,
                    ativação método do pagamento, ativação logística, cadastro
                    da equipe de vendas e gerente.
                  </p>
                </div>
                <div className="image-service">
                  <img
                    id="img1"
                    src="https://assets.houpa.app/images/houpa_hotsite/setup/perfil.png"
                    alt=""
                  />
                </div>
              </Row>
            </Col>
            <Col className="services retracted-description m-top-40">
              <Row className="R2">
                <div
                  className="image-service"
                  style={{ width: tamanho, marginLeft: alinhamento }}>
                  <img
                    id="img2"
                    src="http://assets.houpa.app/images/houpa_hotsite/setup/cadastro-produto-i.png"
                    alt=""
                  />
                </div>
                <div className="description  number" id="n2">
                  <div className="count">2</div>
                  <h2 id="h2">Cadastro de produtos</h2>
                  <p id="p2">
                    Cadastro dos produtos da 1ª coleção da sua marca no houpa!
                    (mínimo de 20 produtos e máximo de 30 produtos).
                    <br />
                    <br />
                    Subida das imagens para melhor destaque das peças.
                    <br />
                    <br />
                    Descrição detalhada de produtos com técnicas de SEO.
                  </p>
                </div>
              </Row>
            </Col>
            <Col className="services retracted m-top-40">
              <Row className="R3">
                <div className="description number" id="n3">
                  <div className="count">3</div>
                  <h2 id="h2">Estoque</h2>
                  <p id="p3">
                    União venda física e online através do QRCODE.
                    <br />
                    <br />
                    Em caso de somente venda online: Instrução sobre contagem e
                    separação do estoque.
                    <br />
                    <br />
                    Integração ERP*
                    <br />
                    *Consulte a disponibilidade.
                  </p>
                </div>

                <div className="image-service">
                  <img
                    id="img3"
                    src="http://assets.houpa.app/images/houpa_hotsite/setup/estoque-i.png"
                    alt=""
                  />
                </div>
              </Row>
            </Col>
            <Col className="services retracted-description m-top-40">
              <Row className="R4">
                <div
                  className="image-service"
                  style={{ width: tamanho, marginLeft: alinhamento }}>
                  <img
                    id="img4"
                    src="http://assets.houpa.app/images/houpa_hotsite/setup/treinamento-i.png"
                    alt=""
                  />
                </div>
                <div className="description number" id="n4">
                  <div className="count">4</div>
                  <h2 id="h2">Treinamentos</h2>
                  <p id="p4">
                    Funcionalidades da plataforma, cadastro de produtos, perfil
                    de vendedoras.
                  </p>
                </div>
              </Row>
            </Col>
            <Col className="services retracted m-top-40">
              <Row className="R5">
                <div className="description number" id="n5">
                  <div className="count">5</div>
                  <h2 id="h2">Peças digitais</h2>
                  <p id="p5">
                    Criação dos enxovais digitais:
                    <br />
                    <br />
                    Foto de capa para sua loja.
                    <br />
                    <br />
                    Artes para divulgação nos canais online: Facebook, Instagram
                    e WhatsApp.
                  </p>
                </div>
                <div className="image-service">
                  <img
                    id="img5"
                    src="http://assets.houpa.app/images/houpa_hotsite/setup/pecas-digitais-i.png"
                    alt=""
                  />
                </div>
              </Row>
            </Col>
          </Col>
        </Container>
      </Section>

      <Section ref={PriceRef} className="bg gray w-936">
        <Container>
          <Col className="center p-top-80">
            <h1 className="purple">O sucesso da sua marca por apenas..</h1>
            <Card>
              <p className="title">Consultoria Setup</p>
              <p className="price">
                <span>R$</span>
                1000
                <sup>,00</sup>
              </p>

              <p className="footer">
                8 horas de trabalho dedicados à sua marca.
              </p>
            </Card>
          </Col>
        </Container>
      </Section>

      <Section ref={ServicesRef} className="w-1007">
        <Container>
          <Col className="p-top-44">
            <Col className="p-top-44">
              <Col className="center  w-904">
                <h1 className="purple" id="servicos">
                  O houpa! tem serviços pensados sob medida para o seu negócio.
                  Conheça!
                </h1>
              </Col>
              <Col>
                <Row className="space-between">
                  <Card className="service bg grey">
                    <Col className="center">
                      <i className="icon icon-document-list" />
                      <p>Planos Comerciais</p>
                    </Col>
                  </Card>
                  <Card className="service bg grey">
                    <Col className="center">
                      <i className="icon icon-document" />
                      <p>Relatórios Gerenciais</p>
                    </Col>
                  </Card>
                  <Card className="service bg grey">
                    <Col className="center">
                      <i className="icon icon-monitor-pencil" />
                      <p>Agência digital houpa!</p>
                    </Col>
                  </Card>
                </Row>
              </Col>
            </Col>
          </Col>
        </Container>
      </Section>

      <FooterB2B />
    </>
  );
};

export default Setup;
