import styled, { css, CSSProp } from 'styled-components';

type bg = 'purple' | 'white';

interface PropsLi {
  purple?: boolean;
}

interface PropsSection {
  backgroundUrl?: string;
  bgContainer?: bg;
}

interface PropsButton {
  bgContainer?: bg;
}

interface IBgColor {
  purple: CSSProp;
  white: CSSProp;
}

const bgColor: IBgColor = {
  purple: css`
    background-color: var(--purple-business-dark);
  `,
  white: css`
    background-color: var(--white);
  `,
};

const color: IBgColor = {
  white: css`
    color: var(--purple-business-dark);
  `,
  purple: css`
    color: var(--white);
  `,
};

export const Li = styled.li<PropsLi>`
  a,
  button {
    font-family: Roboto-Medium;

    ${({ purple }) =>
      purple
        ? css`
            font-family: Roboto-Black;
            color: var(--purple-business-dark);
          `
        : css`
            color: var(--greyDark);
          `}
  }
`;

export const Container = styled.div`
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;

  &.flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.column {
    flex-direction: column;
  }

  &.aboutHoupa {
    max-width: 1160px;

    @media (max-width: 1279px) {
      max-width: var(--container);

      h1 {
        margin-bottom: 40px;
        align-self: flex-start;
      }
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.space-between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.column {
    flex-direction: column;
  }

  &.reverse-column-md {
    @media (max-width: 1279px) {
      flex-direction: column-reverse;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 45px 67px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    width: 100%;

    & > div {
      width: 100%;
    }
  }
`;

export const Section = styled.section<PropsSection>`
  &.bg {
    &.image {
      background-image: url('${({ backgroundUrl }) => backgroundUrl}');
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.no-image-md {
      @media (max-width: 1279px) {
        background-image: none;
      }
    }

    &.no-image-sm {
      @media (max-width: 607px) {
        background-image: none;
      }
    }

    &.grey-color-sm {
      @media (max-width: 607px) {
        background-color: #efefef;
      }
    }

    &.color {
      ${({ bgContainer }) => bgColor[bgContainer || 'white']}
    }

    &.resize-bg {
      background-position: bottom right;
      background-size: auto;
    }
  }

  .banner-top {
    flex: 0 0 419px;

    @media (max-width: 1279px) {
      flex-basis: 326px;
      height: 785px;
    }

    @media (max-width: 607px) {
      flex-basis: 312px;
      height: 290px;
    }

    h1 {
      line-height: 46px;

      @media (max-width: 1279px) {
        line-height: 36px;
      }
    }

    h2 {
      width: 100%;
      max-width: 321px;
      font-size: 16px;
      line-height: 26px;
      font-family: Roboto-Regular;
      margin-top: 15px;

      @media (max-width: 1279px) {
        max-width: 284px;
      }
    }

    button {
      margin-bottom: 42px;
    }

    & > .list {
      font-size: 14px;
      line-height: 24px;
      p {
        color: var(--purple-business-dark);
        letter-spacing: 0px;
        font-family: Roboto-Bold;
      }

      li {
        color: var(--black);
        font-family: Roboto-Regular;

        i {
          color: var(--purple-business-dark);
          margin-right: 11px;
        }
      }

      @media (max-width: 1279px) {
        ul {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 0px 13px;
        }
      }
    }
  }

  h1 {
    font-family: Poppins-Medium;
    font-size: 36px;
    color: var(--greyDark);
    font-weight: 500;

    @media (max-width: 1279px) {
      font-size: 28px;
    }

    @media (max-width: 607px) {
      font-size: 26px;
    }

    &.purple {
      color: var(--purple-business-dark);
    }

    &.white {
      color: var(--white);
    }

    &.title-small-od {
      @media (max-width: 1279px) {
        font-size: 26px;
        align-self: center;
        margin-bottom: 15px;
      }
    }
  }

  h2 {
    font-family: Poppins-Medium;
    margin-bottom: 16px;
    color: var(--greyDark);

    &.black {
      color: var(--black);
      font-weight: 500;
    }
  }

  .subtitle {
    font-size: 18px;
    line-height: 24px;
  }

  .text {
    flex: 1 1 463px;

    .subtitle {
      font-size: 18px;
      line-height: 24px;
    }
  }

  figure {
    flex: 1 1 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      object-fit: contain;
    }

    figcaption {
      font-family: Roboto-Medium;
      font-size: 14px;
      line-height: 20px;
      max-width: 174px;
      text-align: center;
      margin-left: 10px;
    }
    @media (max-width: 1279px) {
      max-width: 423px;
    }
  }

  &.aboutHoupa {
    h2 {
      font-size: 32px;
    }

    figure {
      flex: 1 1 600px;

      @media (max-width: 1279px) {
        flex-basis: 375px;
        max-width: 374px;
        max-height: 268px;
      }
    }

    .text {
      flex: 1 1 463px;
      font-size: 18px;
      line-height: 24px;

      strong {
        font-family: Roboto-Bold;
      }

      &.mission {
        flex: 1 1 418px;

        @media (max-width: 1279px) {
          flex: 1;
        }
      }
    }
  }

  &.return-top-top {
    padding: 40px 0px;
    border-bottom: 1px solid var(--purple-business-dark);

    font-family: Roboto-Bold;
    font-size: 14px;
    line-height: 24px;
    i {
      font-size: 14px;
      margin-right: 20px;
    }
  }

  .pt-77 {
    padding-top: 77px;

    @media (max-width: 1279px) {
      padding-top: 46px;
    }
  }

  .pb-77 {
    padding-bottom: 77px;

    @media (max-width: 1279px) {
      padding-bottom: 46px;
    }
  }

  .pt-37 {
    padding-top: 37px;
  }

  .pb-66 {
    padding-bottom: 66px;

    @media (max-width: 1279px) {
      padding-bottom: 46px;
    }
  }

  .mt-44 {
    margin-top: 44px;
  }

  .text-white {
    color: var(--white);
  }

  .contact-us {
    width: 280px;
    cursor: pointer;
    height: 89px;
    background-color: var(--white);
    position: absolute;
    right: 5%;
    margin-left: 20px;
    margin-top: 100px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    text-decoration: none;
    color: var(--black);
    i {
      font-size: 60px;
      color: var(--purple-business-dark);
    }
    #title {
      font-size: 14px;
      font-weight: bold;
      color: var(--purple-business-dark);
    }
    @media only screen and (max-width: 480px) {
      display: none;
    }
  }
`;

export const Form = styled.form`
  flex: 0 0 394px;
  background: var(--white);
  border-radius: 32px;
  height: 670px;

  padding: 37px;

  .head {
    font-size: 18px;
    line-height: 24px;
    font-family: Roboto-Bold;
    margin-bottom: 20px;
  }

  .hs_firstname {
    color: red !important;
  }
`;

export const Button = styled.button<PropsButton>`
  width: 164px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  margin-top: 20px;
  transition: opacity 0.2s;

  ${({ bgContainer }) => bgColor[bgContainer || 'purple']}
  ${({ bgContainer }) => color[bgContainer || 'purple']}


  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;

export const Video = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1279px) {
    margin-top: 10px;
  }

  iframe {
    width: 895px;
    height: 512px;
  }
`;

export const Card = styled.div`
  width: 293px;

  background: var(--white);
  box-shadow: 15px 25px 25px #0000001a;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0.5fr 0.7fr;
  text-align: center;

  padding: 31px;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .title {
    font-family: Roboto-Medium;
    font-size: 18px;
    color: var(--greyDark);
    margin-bottom: 0px;
  }

  > .description {
    font-size: 16px;
    line-height: 18px;
    color: var(--grey);
  }
`;
