import React from 'react';

import { AcknowledgmentBox } from './styles';

interface Props {
  title?: string;
  text?: string;
  maxWidth?: any;
}

const Acknowledgment: React.FC<Props> = ({ title, text, maxWidth }) => {
  return (
    <AcknowledgmentBox maxWidthSize={maxWidth}>
      <div id="container">
        <i className="icon icon-check-circle" />
        <span>
          <h1 id="title">{title}</h1>
          {text}
        </span>
      </div>
    </AcknowledgmentBox>
  );
};

export default Acknowledgment;
