import styled from 'styled-components';

export const Container = styled.div`
  width: var(--container);
  margin: 0 auto;
`;

export const Li = styled.li`
  button {
    color: #ffffff;
  }
`;

export const Main = styled.main`
  padding: 0px 250px;
  width: 100%;
  height: 706px;
  display: flex;
  align-items: center;
  background-image: url('http://assets.houpa.app/images/houpa_hotsite/setup/bannerp.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #e6e6e6;

  @media only screen and (max-width: 1279px) {
    padding: 0px;
    background-image: url('http://assets.houpa.app/images/houpa_hotsite/setup/bannerpt.png');
    height: 390px;
  }
  @media only screen and (max-width: 720px) {
    padding: 0px;
    background-image: none;
    height: 390px;
    background-color: #e6e6e6;
  }

  .description {
    padding: 0px;
    width: 62%;
    margin-top: -20%;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1279px) {
      width: 65%;
      margin-left: 7%;
      margin-top: auto;
      margin-bottom: 10%;
    }

    @media only screen and (max-width: 720px) {
      width: 100%;
      margin: auto;
    }

    h1 {
      width: 90%;
      text-align: left;
      color: var(--purple-business-dark);
      font-family: 'Poppins-Bold';
      font-size: 48px;
      border-left: 2px solid var(--purple-business-dark);
      padding-left: 20px;
      margin-left: -21px;
      line-height: 60px;

      @media only screen and (max-width: 1279px) {
        font-size: 23px;
        width: 100%;
        line-height: 30px;
      }
    }

    p {
      width: 65%;
      font-family: 'Roboto-Medium';
      font-size: 18px;
      color: var(--purple-business-dark);

      @media only screen and (max-width: 1279px) {
        font-size: 15px;
        width: 75%;
      }

      @media only screen and (max-width: 720px) {
        width: 95%;
      }

      &:first-of-type {
        margin-top: 10px;
      }

      & ~ p {
        margin-top: 20px;
      }
    }
  }

  .contact-us {
    width: 280px;
    cursor: pointer;
    height: 89px;
    background-color: var(--white);
    position: absolute;
    right: 5%;
    margin-left: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    text-decoration: none;
    color: var(--black);
    i {
      font-size: 60px;
      color: var(--purple-business-dark);
    }
    #title {
      font-size: 14px;
      font-weight: bold;
      color: var(--purple-business-dark);
    }
    @media only screen and (max-width: 480px) {
      display: none;
    }
  }
`;

export const FormContatoTM = styled.div`
  width: 100%;
  background-color: #e6e6e6;
  height: 721px;
  padding: 30px;
  margin-top: -80px;
  .tituloPlanner {
    padding: 20px;
    h1 {
      width: 70%;
      text-align: left;
      font-size: 22px;
      color: #222222;
      font-weight: 900;
      font-family: Roboto-Bold;
      margin-top: 10px;
      margin-left: 8%;
      @media only screen and (max-width: 720px) {
        font-size: 22px;
        width: 95%;
        color: #000000;

        :nth-child(2) {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
  #my-hubspot-form {
    width: 80%;
    margin: auto;
  }
`;

export const FormContato = styled.form`
  font-size: 15px;

  width: 394px;
  height: max-content;
  background-color: #ffffff;
  border-radius: 32px;
  box-shadow: -15px 25px 25px #10101029;
  margin: auto;
  margin-top: 45px;
  .tituloPlanner {
    h1 {
      text-align: left;
      font-size: 16px;
      color: #222222;
      font-weight: 900;
      font-family: Roboto-Bold;
      margin-top: 10px;
      padding: 20px;
    }
  }
  #my-hubspot-form {
    width: 90%;
    margin: auto;
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 60px;
  @media only screen and (max-width: 720px) {
    padding: 0px 0px;
    margin: auto;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    @media only screen and (max-width: 720px) {
      flex-direction: column-reverse;
    }
  }

  .image {
    flex: 0 0 591px;
    height: 599px;
    @media only screen and (max-width: 1279px) {
      flex: 0 0 373px;
      height: 378px;
      margin-left: -10%;
    }

    & ~ .description {
      margin-left: -100px;
      margin-top: 120px;

      @media only screen and (max-width: 1279px) {
        margin-left: -10px;
        margin-top: 70px;
      }
    }
  }

  h1 {
    font-size: 42px;
    line-height: 52px;
    font-family: 'Poppins-SemiBold';

    @media only screen and (max-width: 1279px) {
      &#servico {
        width: 60%;
        font-size: 26px;
        text-align: center;
      }
    }
    @media only screen and (max-width: 720px) {
      &#servico {
        width: 28%;
        font-size: 26px;
        text-align: center;
      }
    }

    &.lonline {
      @media only screen and (max-width: 1279px) {
        font-size: 23px;
        width: 45%;
        line-height: 30px;
      }
      @media only screen and (max-width: 720px) {
        width: 85%;
        margin: auto;
      }
    }

    &.purple {
      color: var(--purple-business-dark);
      @media only screen and (max-width: 1279px) {
        font-size: 42px;
        width: 70%;
        line-height: 52px;
        margin: auto;
      }
      @media only screen and (max-width: 720px) {
        font-size: 30px;
        width: 100%;
        line-height: 52px;
        font-weight: 600;
        margin-top: -30px;
      }
    }
    &#servicos {
      @media only screen and (max-width: 1279px) {
        width: 95%;
        font-size: 40px;
        text-align: center;
      }
      @media only screen and (max-width: 720px) {
        width: 100%;
        font-size: 30px;
      }
    }
  }

  h2 {
    font-family: 'Poppins-Medium';
    font-size: 32px;
    line-height: 52px;
    &#h2 {
      @media only screen and (max-width: 1279px) {
        width: 100%;
        font-size: 20px;
        text-align: left;
        line-height: 18px;
        margin-top: 10px;
      }
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    margin-top: 19px;
    @media only screen and (max-width: 1279px) {
      &.lonlinep {
        font-size: 15px;
        width: 51%;
      }
      &#servicop {
        width: 60%;
      }
      &#p2 {
        width: 80%;
        font-size: 15px;
      }
    }
    @media only screen and (max-width: 720px) {
      &.lonlinep {
        font-size: 15px;
        width: 80%;
        margin: auto;
      }
      &#servicop {
        width: 28%;
      }
      &#p1 {
        width: 30%;
        font-size: 15px;
      }
      &#p2 {
        width: 29%;
        font-size: 15px;
      }
      &#p3 {
        width: 40%;
        font-size: 15px;
      }
      &#p4 {
        width: 45%;
        font-size: 15px;
      }
      &#p5 {
        width: 45%;
        font-size: 15px;
      }
    }
  }

  .description {
    flex: 0 0 652px;
    position: relative;
    @media only screen and (max-width: 720px) {
      flex: 0 0 0;
    }

    h1 {
      color: var(--purple-business-dark);
      letter-spacing: -0.84px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      margin-top: 20px;
    }
    .descConsultoria {
      @media only screen and (max-width: 1279px) {
        width: 80%;
      }
    }

    &.number {
      border-left: 2px solid var(--white);
      height: fit-content;
      @media only screen and (max-width: 720px) {
        margin-right: auto;
        margin-left: auto;
        margin-top: -60px;
        margin-bottom: 20px;
        &#n2 {
          margin-top: -23%;
        }
        &#n3 {
          margin-left: -10px;
        }
        &#n4 {
          margin-top: 1%;
        }
        &#n5 {
          margin-left: -15px;
        }
      }
    }

    & > .count {
      position: absolute;
      display: flex;

      width: 43px;
      height: 43px;

      top: -45px;
      left: -23px;

      color: var(--purple-business-dark);
      background-color: var(--white);
      border-radius: 50%;

      font-size: 22px;
      line-height: 52px;

      align-items: center;
      justify-content: center;
    }
  }

  &.w-936 {
    padding: 0px calc((100% - 936px) / 2);
  }

  &.w-1007 {
    padding: 0px calc((100% - 1007px) / 2);
  }

  &.bg {
    &.purple {
      background-color: var(--purple-business-dark);

      h1 {
        color: var(--white);
      }

      h2 {
        color: var(--white);
      }

      p {
        color: var(--white);
      }
    }

    &.gray {
      background-color: var(--greyLight2);
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  flex-wrap: wrap;

  &.services-group {
    width: 1132.5px;
  }

  &.center {
    text-align: center;
  }

  &.p-top-44 {
    padding-top: 44px;
  }

  &.p-top-80 {
    padding-top: 80px;
  }

  &.m-top-40 {
    margin-top: 40px;
  }

  &.w-904 {
    padding: 0px calc((100% - 904px) / 2);
  }

  &.w-666 {
    max-width: 666px;
    flex: 1 0 666px;
  }

  &.services {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: 1279px) {
        width: 82%;
        justify-content: center;
        margin-left: -15px;
      }
    }
  }

  &.retracted {
    width: 990px;
    margin-right: auto;
    margin-left: auto;
  }

  & ~ .services {
    margin-top: 90px;
  }

  .description {
    flex: 0 0 384px;
    padding-left: 15px;
    @media only screen and (max-width: 1279px) {
      flex: 0 0 350px;
    }
    @media only screen and (max-width: 720px) {
      flex: 0 0 268px;
    }

    & ~ .image-service {
      img {
        @media only screen and (max-width: 1279px) {
          &#img1 {
            width: 70%;
            margin-right: auto;
          }
          &#img2 {
            width: 60%;
          }
          &#img3 {
            width: 70%;
          }
          &#img4 {
            width: 35%;
          }
          &#img5 {
            width: 75%;
            margin-left: -20px;
          }
        }
        @media only screen and (max-width: 720px) {
          &#img1 {
            display: flex;
            justify-content: center;
            width: 65%;
            margin-left: -40%;
            margin-top: 10px;
          }
          &#img2 {
            width: 60%;
            /* margin-left: -54%; */
          }
          &#img3 {
            width: 70%;
            margin-left: -52%;
          }
          &#img4 {
            width: 40%;
            margin-left: -70%;
          }
          &#img5 {
            width: 55%;
            margin-left: -23%;
          }
        }
      }
    }
  }

  & > hgroup {
    h1 {
      font-size: 28px;
      line-height: 35px;
      font-family: 'Poppins-SemiBold';
      font-weight: 600;
    }
    h2 {
      font-size: 24px;
      line-height: 35px;
      font-family: 'Poppins-Light';
      font-weight: 300;
    }
  }

  &.retracted-description {
    & > div {
      justify-content: flex-start;
    }

    .image-service {
      & ~ .description {
        margin-left: 50px;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex: 1;

  @media only screen and (max-width: 1279px) {
  }
  @media only screen and (max-width: 720px) {
    &.R1 {
      flex-direction: column;
      margin-bottom: 60px;
      margin-top: -30px;
      align-items: center;
      margin-left: -15px;
    }
    &.R2 {
      flex-direction: column-reverse;
    }
    &.R3 {
      flex-direction: column;
      margin-top: 50px;
    }
    &.R4 {
      flex-direction: column-reverse;
    }
    &.R5 {
      flex-direction: column;
    }
  }

  &.space-between {
    justify-content: space-between;
    @media only screen and (max-width: 1279px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

export const Card = styled.div`
  flex: 0 0 281px;
  background-color: var(--white);
  margin: 40px 11px 48px;
  padding: 25px;

  border-radius: 44px;

  color: var(--purple-business-dark);

  &.bg {
    &.grey {
      background-color: var(--greyLight2);
    }
  }

  &.service {
    border-radius: 32px;
    padding: 61px 50px 47px;
  }

  & > .title {
    font-family: 'Poppins-SemiBold';
    font-size: 28px;
    line-height: 42px;

    padding-bottom: 8px;
    border-bottom: 2px solid var(--greyLight2);
    @media only screen and (max-width: 1279px) {
      width: 100%;
      font-size: 28px;
    }
  }

  i {
    font-size: 42px;
  }

  & > .price {
    font-family: 'Poppins-Medium';
    font-size: 38px;
    line-height: 57px;
    @media only screen and (max-width: 1279px) {
      width: 100%;
      font-size: 38px;
    }

    span,
    sup {
      font-size: 22px;
    }
  }

  & > .footer {
    font-family: 'Roboto-Bold';
    font-size: 38px;
    line-height: 35px;
    @media only screen and (max-width: 1279px) {
      width: 70%;
      margin: auto;
      font-size: 32px;
    }
  }
`;

export const Form = styled.div`
  width: 100%;
  max-width: 505px;
  margin-top: 50px;
`;
