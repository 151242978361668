import styled from 'styled-components';

interface Props {
  maxWidthSize: string;
}

export const AcknowledgmentBox = styled.div<Props>`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 303px;
  margin-top: 1px;
  background-color: var(--purple-business-dark);
  color: var(--white);
  #container {
    width: 100%;
    max-width: ${({ maxWidthSize }) => maxWidthSize};
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 70px 0;
    font-size: 110px;
    i {
      margin-right: 30px;
    }
    span {
      font-size: 18px;
      font-family: 'Roboto', sans-serif !important;
      #title {
        margin-bottom: 10px;
        font-size: 32px;
        font-family: 'Poppins-Light', sans-serif;
        font-weight: bolder;
        letter-spacing: 1px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 10px;
    #container {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      i {
        font-size: 60px;
        margin-right: 0;
      }
    }
  }
`;
