import styled, { css } from 'styled-components';

interface PropsLi {
  purple?: boolean;
}
export const Li = styled.li<PropsLi>`
  margin: auto;
  button {
    font-family: Roboto-Medium;
    color: #222222;
  }

  a {
    font-weight: 600;
    font-family: Roboto-Black;
  }
`;

export const BannerInicio = styled.div`
  background-image: url('http://assets.houpa.app/images/houpa_hotsite/marcas/bannerprincipal.png');
  width: 100%;
  height: 551px;
  background-repeat: no-repeat;
  background-position: center;
  .contact-us {
    width: 280px;
    cursor: pointer;
    height: 89px;
    background-color: var(--white);
    position: absolute;
    right: 5%;
    margin-left: 20px;
    margin-top: 180px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    text-decoration: none;
    color: var(--black);
    i {
      font-size: 60px;
      color: var(--purple-business-dark);
    }
    #title {
      font-size: 14px;
      font-weight: bold;
      margin-top: 0;
      color: var(--purple-business-dark);
    }
    @media only screen and (max-width: 480px) {
      display: none;
    }
  }

  .orientationTxtBanner {
    display: flex;
    flex-direction: column;
  }
  h1 {
    font-family: Poppins-SemiBold;
    font-size: 36px;
    text-align: center;
    color: #ffffff;
    margin-top: 73px;
  }
  h3 {
    font-family: Poppins-Medium;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
  }
  h4 {
    font-family: Roboto-Regular;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
  }
  .orientationEstiloColumn {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 25%;
    height: 20px;
    #tituloh4 {
      margin-top: 25px;
    }
  }
  .orientationEstiloRow {
    display: flex;
    justify-content: space-between;
    h4 {
      margin-top: 13px;
      margin-left: 10px;
    }
    i {
      margin-right: 10px;
      color: #5dcc77;
    }
  }
  .orientationEstiloRow2 {
    display: flex;
    justify-content: space-between;
    h4 {
      margin-top: 13px;
      margin-left: 10px;
    }
    i {
      margin-right: 10px;
      color: #5dcc77;
    }
  }

  @media (max-width: 1758px) {
    .orientationEstiloColumn {
      width: 100%;
    }
    .orientationRow {
      display: flex;
      margin: auto;
      .orientationEstiloRow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .orientationEstiloRow2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    height: 785px;
    background-image: url('http://assets.houpa.app/images/houpa_hotsite/marcas/bannerprincipal2.png');
    .orientationTxtBanner {
      h1 {
        font-size: 28px;
        font-family: Poppins-Medium;
        font-weight: 500;
      }
      h3 {
        font-family: Roboto-Regular;
        font-weight: 500;
      }
    }
  }
`;
export const FormPlanner = styled.form`
  width: 415px;
  height: max-content;
  background-color: #ffffff;
  border-radius: 32px;
  box-shadow: -15px 25px 25px #10101029;
  margin: auto;
  margin-top: 45px;
  z-index: 1;
  .tituloPlanner {
    h1 {
      text-align: left;
      font-size: 18px;
      color: #222222;
      font-weight: 900;
      font-family: Roboto-Bold;
      margin: auto;
      padding: 20px;
    }
  }
  #my-hubspot-form {
    width: 90%;
    margin: auto;
  }
  @media (max-width: 607px) {
    width: 334px;
    .tituloPlanner {
      font-size: 14px;
    }
  }
`;
export const Display = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    div {
      width: 100%;
      height: 370px;
    }
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12%;
    div {
      width: 100%;
      height: 50px;
    }
  }

  @media (max-width: 610px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12%;
    div {
      width: 100%;
      height: 200px;
    }
  }
`;

export const PositionVantagens = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    margin-left: 60px;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
  }
  @media (max-width: 769px) {
    margin: auto;
    .right {
      width: 0%;
      margin: auto;
    }
    .left {
      width: 45%;
    }
  }
  @media (max-width: 615px) {
    width: 100%;
    flex-direction: column;
    padding-left: 20%;

    .right {
      width: 100%;
      align-items: flex-start;
    }
    .left {
      width: 100%;
      align-items: flex-start;
    }
  }
`;

export const Vantagens = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin-bottom: 20px;
  margin-top: 30px;

  .iconVantagens {
    width: 20%;
    background-position: center;
  }
  .orientationVantagens {
    display: flex;
    align-items: center;
  }
  .txtVantagens {
    width: 60%;
    margin-left: 15px;
    h1 {
      font-size: 18px;
      font-family: Roboto-Medium;
      text-align: left;
      color: #000000;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 16px;
      font-weight: 500;
      font-family: Roboto-Regular;
      text-align: left;
      color: #525252;
    }
  }
  @media (max-width: 1279px) {
    .iconVantagens {
      width: 35%;
      background-position: center;
    }
    .txtVantagens {
      width: 60%;
      h2 {
        width: 203px;
      }
    }
  }
  @media (max-width: 400px) {
    .iconVantagens {
      width: 25%;
    }
    .txtVantagens {
      width: 60%;
      h2 {
        width: 221px;
      }
    }
  }
`;
export const Funciona = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 507px;
  background-color: #3f0b6d;
  .imgFunciona {
    img {
      width: 650px;
      height: 404px;
      margin: auto;
    }
    h2 {
      width: 30%;
      margin: auto;
      margin-top: -40px;
      font-size: 14px;
      font-weight: 500;
      font-family: Roboto-Medium;
      text-align: center;
      color: #ffffff;
    }
  }
  .orientationTxtFunciona {
    display: flex;
    flex-direction: column;
    //align-items: center;
    width: 30%;

    .btnFunciona {
      font-size: 18px;
      font-family: Roboto-Regular;
      width: 164px;
      height: 46px;
      text-align: center;
      background-color: #ffffff;
      color: #3f0b6d;
      border-radius: 6px;
      margin-top: 20px;
    }

    h1 {
      font-size: 36px;
      text-align: left;
      font-weight: 600;
      font-family: Poppins-Medium;
      color: #ffffff;
      margin-bottom: 20px;
    }
    h2 {
      width: 70%;
      font-weight: 500;
      font-family: Roboto-Regular;
      color: #ffffff;
    }
  }
  @media (max-width: 1279px) {
    .imgFunciona {
      img {
        width: 500px;
        height: 333px;
      }
      h2 {
        display: none;
      }
    }
    .orientationTxtFunciona {
      width: 100%;
      display: flex;
      margin-left: 15%;
      h1 {
        font-size: 36px;
        font-family: Poppins-Medium;
        font-weight: 500;
        margin-top: 40px;
      }

      h2 {
        width: 82%;
      }

      .btnFunciona {
        display: none;
      }
    }
  }
  @media (max-width: 607px) {
    .imgFunciona {
      img {
        width: 368px;
        height: 243px;
      }
    }
    .orientationTxtFunciona {
      width: 100%;
      display: flex;

      h1 {
        font-size: 30px;
        font-family: Poppins-Medium;
        font-weight: 500;
        margin-top: 40px;
      }

      h2 {
        width: 100%;
      }
    }
  }
`;
export const Conheca = styled.div`
  width: 100%;
  height: max-content;
  h1 {
    margin-top: 75px;
    font-size: 36px;
    font-family: Poppins-Medium;
    font-weight: 500;
    text-align: center;
    color: #222222;
  }
  .orientationUp {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .orientationtxtUp {
      display: flex;
      flex-direction: column;
      width: 27%;
      h1 {
        font-size: 32px;
        font-family: Poppins-Medium;
        font-weight: 500;
        text-align: left;
        color: #000000;
      }
      h2 {
        width: 91%;
        margin-top: 10px;
        font-size: 18px;
        font-family: Roboto-Regular;
        font-weight: 500;
        text-align: left;
        color: #222222;
      }
    }
    .imgConheca {
      width: 600px;
      height: 400px;
    }
  }
  @media (max-width: 1279px) {
    h1 {
      width: 68%;
      text-align: center;
      margin-top: 30px;
    }
    .orientationUp {
      width: 100%;
      :nth-child(3) {
        flex-wrap: wrap-reverse;
      }
      .orientationtxtUp {
        width: 75%;
        h1 {
          width: 100%;
          text-align: left;
        }

        h2 {
          text-align: left;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 607px) {
    h1 {
      width: 100%;
      font-size: 30px;
      text-align: center;
      margin-top: 30px;
    }
    .orientationUp {
      width: 100%;
      :nth-child(3) {
        flex-wrap: wrap-reverse;
      }
      .orientationtxtUp {
        width: 90%;
        h1 {
          width: 100%;
          font-size: 24px;
          text-align: left;
        }
        :nth-child(2) {
          margin-top: -40px;
        }

        h2 {
          text-align: left;
          width: 100%;
        }
      }
      .imgConheca {
        width: 375px;
        height: 268px;
      }
    }
  }
`;
export const Video = styled.div`
  width: 100%;
  height: 754px;
  background-image: url('http://assets.houpa.app/images/houpa_hotsite/marcas/02_video.png');
  padding-top: 75px;
  margin-top: 30px;

  h1 {
    font-family: Poppins-Medium;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: #222222;
    margin-bottom: 45px;
  }
  .video {
    width: 895px;
    height: 503px;
    margin: auto;
  }
  .topo {
    button {
      margin-top: 30px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 1279px) {
    width: 100%;
    height: max-content;
    background-image: none;
    background-color: #e7e7e7;
    h1 {
      font-size: 24px;
      margin-bottom: 25px;
    }
    .video {
      width: 608px;
      height: 342px;
    }
    .topo {
      padding: 20px;
    }
  }

  @media (max-width: 800px) {
    padding-top: 45px;
    h1 {
      font-size: 24px;
    }
    .video {
      width: 80%;
      height: 342px;
    }
    .topo {
      padding: 10px;
    }
  }
  @media (max-width: 609px) {
    .video {
      height: 193px;
    }
  }
`;
